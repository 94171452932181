<!-- 用户详情--基本信息模块 -->
<template>
  <div class="user-info-edit">
    <Form ref="formInline" :model="formInline" :rules="ruleInline" :label-width="100">
      <div class="section">
        <!-- <div class="section-hd">基本信息</div> -->
        <div class="section-bd">
          <div class="item">
            <FormItem label="用户ID：">
              <Input type="text" v-model="formInline.id" disabled style="width:300px"></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="真实姓名：">
              <Input
                type="text"
                v-model="formInline.userName"
                placeholder="请输入真实姓名"
                style="width:300px"
              ></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="手机号码：">
              <Input
                type="text"
                v-model="formInline.mobile"
                placeholder="请输入手机号码"
                style="width:300px"
              ></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="生日：">
              <DatePicker
                type="date"
                format="yyyy-MM-dd"
                v-model="formInline.birthday"
                placeholder="请选择生日"
                style="width: 300px"
              ></DatePicker>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="身份证号：">
              <Input
                type="text"
                v-model="formInline.idCard"
                placeholder="请输入身份证号"
                style="width:300px"
              ></Input>
            </FormItem>
          </div>
          <!-- <div class="item">
            <FormItem label="户籍地址：">
              <Input type="text" placeholder="请输入户籍地址" style="width:300px"></Input>
            </FormItem>
          </div> -->
        </div>
      </div>
      <!-- <div class="section">
        <div class="section-hd">密码</div>
        <div class="section-bd">
          <div class="item">
            <FormItem label="登陆密码：">
              <Input type="text" placeholder="请输入登录密码（修改用户可不填写，不填写不修改原密码）" style="width:300px"></Input>
            </FormItem>
          </div>
          <div class="item">
            <FormItem label="确认密码：">
              <Input type="text" placeholder="请输入确认密码（修改用户可不填写，不填写不修改原密码）" style="width:300px"></Input>
            </FormItem>
          </div>
        </div>
      </div> -->
      <!-- <div class="section">
        <div class="section-hd">用户概况</div>
        <div class="section-bd">
          <div class="item">
            <div>推广资格：</div>
            <div class="value">开启</div>
          </div>
          <div class="item">
            <div>用户状态：</div>
            <div class="value">开启</div>
          </div>
          <div class="item">
            <div>用户等级：</div>
            <div class="value">-</div>
          </div>
          <div class="item">
            <div>用户标签：</div>
            <div class="value">-</div>
          </div>
          <div class="item">
            <div>用户分组：</div>
            <div class="value">-</div>
          </div>
          <div class="item">
            <div>推广人：</div>
            <div class="value">（0）</div>
          </div>
          <div class="item">
            <div>注册时间：</div>
            <div class="value">2023-11-14 15:07:04</div>
          </div>
          <div class="item">
            <div>登录时间：</div>
            <div class="value">2023-11-14 15:07:05</div>
          </div>
        </div>
      </div>-->
      <!-- <div class="section">
        <div class="section-hd">用户备注</div>
        <div class="section-bd">
          <div class="item">
            <FormItem label="用户备注：">
              <Input type="textarea" placeholder="请输入用户备注" style="width:400px" :rows="3"></Input>
            </FormItem>
          </div>
        </div>
      </div>-->
    </Form>
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  created() {
    this.formInline = this.currentData
  },
  data() {
    return {
      formInline: {
        id: '',
        mobile: '',
        userName: '',
        idCard: '',
        birthday: ''
      },
      ruleInline: {
        user: [
          { required: true, message: 'Please fill in the user name', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please fill in the password.', trigger: 'blur' },
          { type: 'string', min: 6, message: 'The password length cannot be less than 6 bits', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
  }

}
</script>
<style lang="scss" scoped>
.user-info-edit {
  padding: 0 20px;
  .section {
    padding: 25px 0;
    border-bottom: 1px dashed #eee;
  }
  .section:first-child {
    padding: 10px 0 25px;
  }
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 50%;
      display: flex;
      margin: 10px 0 0 0;
      font-size: 13px;
      color: #666;
      line-height: 32px;
    }
  }
}
</style>