import { postRequest } from '@/libs/axios';


// 团长审核列表
export const getApplyList = (params) => {
    return postRequest('/api/colonel/apply-page', params)
}
//审核
export const auditApplyColonel = (params) => {
    return postRequest('/api/colonel/audit-apply-colonel', params)
}

// 团长列表
export const getColonelList = (params) => {
    return postRequest('/api/colonel/page', params)
}

//团长详情
export const getDetail = (params) => {
    return postRequest('/api/colonel/detail', params)
}
