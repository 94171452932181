<!-- 团长管理页面 -->
<template>
  <div class="colonelManagement">
    <div class="head-area clr">
      <div class="fl clr left-search">
        <div class="fl search-item">
          <div class="fl head-label-area">团长搜索</div>
          <Input class="fl" v-model="nickName" placeholder="姓名" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">身份证号</div>
          <Input class="fl" v-model="idCard" placeholder="身份证号" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">手机号</div>
          <Input class="fl" v-model="mobile" placeholder="手机号" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">状态</div>
          <Select v-model="status" clearable style="width:200px">
            <Option value="UNAUDIT">待审核</Option>
            <Option value="INEFFECTIVE">已失效</Option>
            <Option value="EFFECTIVE">生效中</Option>
          </Select>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">性别</div>
          <Select v-model="sex" clearable style="width:200px">
            <Option value="1">男</Option>
            <Option value="2">女</Option>
          </Select>
        </div>
      </div>
      <div class="fr right-search-btn">
        <Button type="primary" style="margin:0 0 10px;" icon="ios-search" @click="search">查询</Button>
        <Button @click="reset" icon="ios-refresh">重置</Button>
      </div>
    </div>
    <div class="table-area">
      <!-- <div class="clone-state-list clr">
        <div
          :class="item.type == activeState?'active':''"
          class="clone-state-item fl"
          v-for="(item,key) in cloneStateList"
          :key="key"
        >
          <span class="name">{{item.label}}</span>
          <span>({{item.num}})</span>
        </div>
      </div>-->
      <Table :columns="columns" :data="dataList" :height="tableHeight">
        <template #avatar="{ row }">
          <img style="width:36px;height:36px;margin-top:5px" :src="row.avatar" alt />
        </template>
        <!-- <template #auditStatus="{ row }">
          <div v-if="row.status == 1">待审核</div>
          <div v-if="row.status == 2">审核通过</div>
          <div v-if="row.status == 3">审核不通过</div>
        </template> -->
        <template #sex="{ row }">
          <div v-if="row.sex == 1">男</div>
          <div v-if="row.sex == 2">女</div>
        </template>
        <template #sex="{ row }">
          <div v-if="row.sex == 1">男</div>
          <div v-if="row.sex == 2">女</div>
        </template>
        <template #reason="{ row }">
          <div v-if="row.colonelStatus == 'EFFECTIVE'">{{row.colonelStartTime + '~' + row.colonelEndTime}}</div>
          <div v-else>-</div>
        </template>
        <template #action="{ row }">
          <a @click="checkDetial(row)" style="margin-right:10px">查看</a>
          <a @click="del(row)">删除</a>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <div class="detial-page-area" v-if="modelShow">
      <detialPage
        v-if="modelShow"
        :currentData="currentData"
        @close="modelShow = false"
        @editSuccess="findPageDataList()"
      />
    </div>
  </div>
</template>

<script>
import { getColonelList, getDetail } from '@/api/colonelCenter.js'
import detialPage from './components/detialPage.vue'

export default {
  components: {
    detialPage
  },
  data() {
    return {
      modelShow: false,
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      searchText: '',
      nickName: '',
      idCard: '',
      mobile: '',
      sex: '',
      status: '',
      cloneStateList: [
        {
          label: '全部',
          num: '123',
          type: 0
        },
        {
          label: '在任团长',
          num: '3',
          type: 1
        },
        {
          label: '失效团长',
          num: '12',
          type: 2
        },
        {
          label: '待审核',
          num: '21',
          type: 3
        }
      ],
      tableHeight: window.innerHeight - 300,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: '姓名',
          key: 'userName'
        },
        {
          title: '性别',
          key: 'sex',
          slot: 'sex',
        },
        {
          title: '身份证号',
          key: 'idCard'
        },
        {
          title: '手机号',
          key: 'mobile',
        },
        {
          title: '有效期',
          key: 'reason',
          slot: 'reason',
        },
        {
          title: '操作',
          slot: 'action',
        }
      ],
      dataList: [],
      currentData: {},
      activeState: 0
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    reset() {
      this.searchText = ''
      this.current = 1
      this.nickName = ''
      this.idCard = ''
      this.mobile = ''
      this.sex = ''
      this.status = ''
      this.findPageDataList()
    },
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      let params = { pageIndex: this.current, size: this.size }
      params.nickName = this.nickName
      params.idCard = this.idCard
      params.mobile = this.mobile
      params.sex = this.sex
      if (this.status) {
        params.colonelStatusList = [this.status]
      }
      getColonelList(params).then(res => {
        console.log(res)
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    checkDetial(row) {
      getDetail({ id: row.id }).then(res => {
        if (res.success) {
          this.currentData = res.result
        }
      })
      // this.currentData = JSON.parse(JSON.stringify(row))
      this.modelShow = true
    },
    del(row) {
      console.log(row)
    }
  }
}

</script>
<style lang='scss' scoped>
.colonelManagement {
  // position: relative;
  .left-search {
    width: 900px;
    .search-item {
      margin-bottom: 10px;
    }
  }
  .right-search-btn {
    width: 100px;
    border-left: 1px solid #e5e6eb;
    padding-left: 20px;
  }
  .head-label-area {
    line-height: 36px;
    width: 80px;
    padding-right: 15px;
    text-align: right;
  }
  .clone-state-list {
    margin-bottom: 16px;
    .clone-state-item {
      padding: 0 16px;
      margin-right: 10px;
      height: 30px;
      line-height: 30px;
      cursor: pointer;
    }
    .clone-state-item.active {
      color: #1664ff;
      background: #f2f3f8;
      border-radius: 15px;
    }
  }
  .detial-page-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    background: #fff;
    z-index: 100;
  }
}
</style>