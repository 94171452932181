<template>
  <div class="auditBox">
    <Form :model="formData" :label-width="90">
      <FormItem label="审核理由：">
        <Input v-model="formData.auditMsg" type="textarea" :autosize="{minRows: 5}"></Input>
      </FormItem>
    </Form>
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      formData: {
        auditMsg: "",
      }
    }
  },
  created() {
    // this.formData.id = this.currentData.id
  },
  methods: {

  }
}
</script>

<style>
</style>