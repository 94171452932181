<template>
  <div class="detial-box">
    <div class="head-area clr">
      <div class="avatar fl">
        <img :src="currentData.avatar" alt />
      </div>
      <div class="header-tip fl">
        <!-- <p class="header-tip-title">{{currentData.nickname}}</p> -->
        <div class="header-tip-desc clr">
          <div class="header-tip-desc-sp fl clr">
            <div class="tip-label fl">真实姓名：</div>
            <div class="tip-value fl">{{currentData.userName}}</div>
          </div>
          <div class="header-tip-desc-sp fl clr">
            <div class="tip-label fl">性别：</div>
            <div class="tip-value fl">{{currentData.sex==1?'男':'女'}}</div>
          </div>
          <div class="header-tip-desc-sp fl clr">
            <div class="tip-label fl">账号ID：</div>
            <div class="tip-value fl">{{currentData.id}}</div>
          </div>
          <div class="header-tip-desc-sp fl clr">
            <div class="tip-label fl">手机号码：</div>
            <div class="tip-value fl">{{currentData.mobile}}</div>
          </div>
          <div class="header-tip-desc-sp fl clr">
            <div class="tip-label fl">注册时间：</div>
            <div class="tip-value fl">{{currentData.registerTime}}</div>
          </div>
          <div class="header-tip-desc-sp fl clr">
            <div class="tip-label fl">实名认证：</div>
            <div class="tip-value fl" :class="'status' + currentData.status" v-if="currentData.status == 0">未实名认证</div>
            <div class="tip-value fl" :class="'status' + currentData.status" v-if="currentData.status == 1">未人脸识别认证</div>
            <div class="tip-value fl" :class="'status' + currentData.status" v-if="currentData.status == 2">已实名认证</div>
          </div>
        </div>
      </div>
      <div class="edit-btn">
        <Button style="margin-right:10px" @click="$emit('close')">返回</Button>
        <!-- <Button type="primary" v-if="type=='check'" @click="type = 'edit';tabVal = '1'">编辑</Button>
        <Button v-if="type!='check'" @click="type = 'check'">取消</Button>
        <Button v-if="type!='check'" type="primary" style="margin-left:10px" @click="save">保存</Button> -->
      </div>
    </div>
    <div class="content-area">
      <!-- <userInfo v-if="tabVal==1 && type=='check'" :currentData="currentData" />
      <userInfoEdit v-if="tabVal==1 && type!='check'" :currentData="currentData" /> -->
      <Tabs v-model="tabVal" :animated="false">
        <TabPane label="基本信息" name="1">
            <userInfo v-if="tabVal==1 && type=='check'" :currentData="currentData" />
            <userInfoEdit v-if="tabVal==1 && type!='check'" :currentData="currentData" /> 
        </TabPane>
      </Tabs>
    </div>
    <div class="btns-area clr" v-if="type=='check' && currentData.applyAuditStatus == 'UNAUDIT'">
      <Button type="primary" style="margin-right:20px" @click="modelShow = true">审核通过</Button>
      <Button @click="refuse">拒绝申请</Button>
    </div>
     <Drawer title="审核" v-model="modelShow" width="600" :mask-closable="false" :styles="styles">
      <auditBox ref="auditBox" v-if="modelShow" :currentData="currentData" />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="saveAudit">确定</Button>
      </div>
    </Drawer>
  </div>
</template>

<script>
import userInfo from './userInfo.vue'
import userInfoEdit from './userInfoEdit.vue'
import { editMember } from '@/api/memberCenter.js'
import { auditApplyColonel } from '@/api/colonelCenter.js'
import auditBox from './auditBox.vue'

export default {
  components: {
    userInfo,
    userInfoEdit,
    auditBox
  },
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      styles: {
        height: 'calc(100% - 55px)',
        overflow: 'auto',
        paddingBottom: '53px',
        position: 'static'
      },
      modelShow:false,
      // currentData: {
      //   avatar: 'http://dgq.hnhslfs.com/avatar/default.png',
      //   nickname: '王力群',
      //   name: '王力群',
      //   sex: '男',
      //   id: '15012312300',
      //   phone: '150******00',
      //   date: '2013-05-10 12:10:00'
      // },
      type: 'check',
      tabVal: '1'
    }
  },
  methods: {
    save() {
      let params = JSON.parse(JSON.stringify(this.currentData))
      params.birthday = this.dateFormat(new Date(params.birthday))
      Object.keys(params).forEach(item => {
        if (params[item] == null) {
          params[item] = ''
        }
      })
      editMember(params).then(res => {
        if (res.success) {
          this.type = 'check'
          this.currentData = params
          this.$Message.success('修改成功！')
          this.$emit('editSuccess')
        }
      })
    },
    saveAudit() {
      let params = {}
      params.auditStatus = 'AUDIT_SUCCESS'
      params.userId = this.currentData.id
      params.auditMsg = this.$refs.auditBox.formData.auditMsg
      auditApplyColonel(params).then(res => {
        if (res.success) {
          this.$Message.success('审核成功！')
          this.$emit('editSuccess')
          this.currentData.colonelStatus = 'EFFECTIVE'
          this.modelShow = false
        }
      })
    },
    refuse(){
      auditApplyColonel({auditStatus:'AUDIT_FAIL',userId:this.currentData.id}).then(res => {
        if (res.success) {
          this.$Message.success('已拒绝申请！')
          this.$emit('editSuccess')
          this.currentData.colonelStatus = 'INEFFECTIVE'
          this.modelShow = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detial-box {
  .head-area {
    position: relative;
    padding: 0 20px;
    .avatar {
      width: 100px;
      height: 100px;
      margin-right: 15px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .header-tip {
      padding-top: 10px;
      width: 72%;
      vertical-align: middle;
      .header-tip-title {
        font-size: 14px;
        color: #000;
        margin-bottom: 12px;
      }
      .header-tip-desc-sp {
        width: 50%;
        color: #4e5969;
        font-size: 14px;
        line-height: 30px;
        .tip-label {
          width: 80px;
          margin-right: 10px;
          text-align: right;
        }
      }
    }
    .edit-btn {
      position: absolute;
      right: 10px;
      top: 0;
    }
  }
  .content-area {
    margin-top: 14px;
  }
  .btns-area {
    margin-top: 20px;
    padding-left: 30px;
  }
  .tip-value.status2{
    color: #00B42A;
  }
}
</style>