<!-- 用户详情--基本信息模块 -->
<template>
  <div class="user-info">
    <div class="section">
      <!-- <div class="section-hd">基本信息</div> -->
      <div class="section-bd">
        <!-- <div class="item">
          <div>用户ID：</div>
          <div class="value">{{currentData.id}}</div>
        </div>-->
        <div class="item">
          <div>用户名：</div>
          <div class="value">{{currentData.nickname}}</div>
        </div>
        <div class="item">
          <div>团长状态：</div>
          <div class="value" v-if="currentData.colonelStatus == 'UNAUDIT'">待审核</div>
          <div class="value" v-if="currentData.colonelStatus == 'INEFFECTIVE'">已失效</div>
          <div class="value" v-if="currentData.colonelStatus == 'EFFECTIVE'">生效中</div>
        </div>
        <div class="item">
          <div>申请理由：</div>
          <div class="value">{{currentData.applyColonelReason}}</div>
        </div>
        <div class="item" v-if="currentData.applyAuditStatus !== 'UNAUDIT'">
          <div>审核理由：</div>
          <div class="value">{{currentData.applyAuditMsg}}</div>
        </div>
        <!-- <div class="item">
          <div>用户地址：</div>
          <div class="value">-</div>
        </div>-->
      </div>
    </div>
    <!-- <div class="section">
      <div class="section-hd">密码</div>
      <div class="section-bd">
        <div class="item">
          <div>登录密码：</div>
          <div class="value">********</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-hd">用户概况</div>
      <div class="section-bd">
        <div class="item">
          <div>推广资格：</div>
          <div class="value">开启</div>
        </div>
        <div class="item">
          <div>用户状态：</div>
          <div class="value">开启</div>
        </div>
        <div class="item">
          <div>用户等级：</div>
          <div class="value">-</div>
        </div>
        <div class="item">
          <div>用户标签：</div>
          <div class="value">{{currentData.tag}}</div>
        </div>
        <div class="item">
          <div>用户分组：</div>
          <div class="value">-</div>
        </div>
        <div class="item">
          <div>推广人：</div>
          <div class="value">（0）</div>
        </div>
        <div class="item">
          <div>注册时间：</div>
          <div class="value">2023-11-14 15:07:04</div>
        </div>
        <div class="item">
          <div>登录时间：</div>
          <div class="value">2023-11-14 15:07:05</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-hd">用户备注</div>
      <div class="section-bd">
        <div class="item">
          <div>备注：</div>
          <div class="value">-</div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
    }
  },

}
</script>
<style lang="scss" scoped>
.user-info {
  padding: 0 20px;
  .section {
    padding: 25px 0;
    border-bottom: 1px dashed #eee;
  }
  .section:first-child {
    padding: 10px 0 25px;
  }
  .section-hd {
    padding-left: 10px;
    border-left: 3px solid #0256ff;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #303133;
  }
  .section-bd {
    display: flex;
    flex-wrap: wrap;
    .item {
      // flex: 0 0 calc(33.33333% - 20px);
      flex: 0 0 calc(100%);
      display: flex;
      margin: 16px 30px 0 0;
      font-size: 13px;
      color: #666;
    }
    // .item:nth-child(3n + 3) {
    //   margin: 16px 0 0;
    // }
  }
}
</style>